import { Pane } from "evergreen-ui";

export const AppBox = ({ children }) => {
  return (
    <Pane
      width="100%"
      minHeight="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
      background={`linear-gradient(to right, rgba(92, 133, 255, 0.7), rgba(117, 202, 170, 0.7))`}
    >
      <Pane
        elevation={4}
        width={500}
        maxWidth="95%"
        marginTop={-180}
        display="flex"
        flexDirection="column"
        gap={20}
        padding={50}
        borderRadius={5}
        color="white"
        background="#2a2b2b"
      >
        {children}
      </Pane>
    </Pane>
  );
};
