import { Pane, Heading, Label } from "evergreen-ui";
import { theme } from "../theme";

export const Navbar = () => {
  return (
    <Pane
      elevation={4}
      position="fixed"
      top={0}
      left={0}
      width="100%"
      height={60}
      display="flex"
      alignItems="center"
      gap={5}
      padding={20}
      background={theme.colors.black}
    >
      <img
        alt="</>"
        src="./assets/bluegreen_icon.png"
        width={25}
        height={25}
        style={{ borderRadius: "50px" }}
      />

      <Pane display="flex" alignItems="center" gap={3}>
        <Heading color="white" fontSize="1.2rem" lineHeight={1.4}>
          <Label fontSize="inherit" color={theme.colors.blue}>
            Blue
          </Label>
          <Label fontSize="inherit" color={theme.colors.green}>
            green
          </Label>
        </Heading>
      </Pane>
    </Pane>
  );
};
