import "./App.css";
import {
  AppBox,
  Navbar,
  Title,
  SubTitle,
  Links,
  SuccessDialog,
  Footer,
} from "./components";

function App() {
  return (
    <>
      <Navbar />
      <AppBox>
        <Title />
        <SubTitle />
        <Links />
        <SuccessDialog />
      </AppBox>
      <Footer />
    </>
  );
}

export default App;
