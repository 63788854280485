import { Pane, Link, Text } from 'evergreen-ui'
import { theme } from "../theme";

export const Links = () => {
    return(
        <Pane display="flex" flexDirection="column" gap={10}>
        <Link width="fit-content" href="https://togogo.app" target="_blank">
          <Text color={theme.colors.green} textDecoration="underline">
            Recent Project
          </Text>
        </Link>
        <Link cursor="pointer" width="fit-content" href='mailto:bluegreendev5@gmail.com'>
          <Text color={theme.colors.green} textDecoration="underline">
            Contact Us
          </Text>
        </Link>
      </Pane>

    )
}