import { Pane, Text } from "evergreen-ui";
import { theme } from "../theme";
export const Footer = () => {
  return (
    <Pane
      position="fixed"
      bottom={0}
      width="100%"
      display="flex"
      justifyContent="center"
      background={theme.colors.black}
      paddingY={20}
    >
      <Text color='lightgrey' fontSize={11}>© {new Date().getFullYear()} Bluegreen</Text>
    </Pane>
  );
};
